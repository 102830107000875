import {FacetType, SearchConfig, Site} from '@app/models';
import {cruiseTypeLabelMap} from '@app/search-configs/facet-bucket-map';

export const SEARCH_CONFIGS: {[key in Site]: SearchConfig} = {
  Alaska: {
    facetConfig: {
      facets: [
        {
          apiField: 'CruiselineID',
          field: 'cruiseline_id',
          label: 'Cruise Line',
          type: FacetType.cruiseline,
          icon: 'helm',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
        {
          apiField: 'CruiseType',
          field: 'cruise_type',
          label: 'Trip Type',
          type: FacetType.standard,
          icon: 'ship',
          search: false,
          labels: cruiseTypeLabelMap,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'dest_id',
          FacetValue: '2',
        },
      ],
    },
  },
  Azamara: {
    facetConfig: {
      facets: [
        {
          apiFields: ['DestID', 'Europe'],
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: FacetType.destination,
          icon: 'map',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_id',
          FacetValue: '1043',
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  CI: {
    facetConfig: {
      facets: [
        {
          apiFields: ['DestID', 'Europe'],
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: FacetType.destination,
          icon: 'map',
          search: true,
        },
        {
          apiField: 'CruiselineID',
          field: 'cruiseline_id',
          label: 'Cruise Line',
          type: FacetType.cruiseline,
          icon: 'helm',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
        {
          apiField: 'CruiseType',
          field: 'cruise_type',
          label: 'Trip Type',
          type: FacetType.standard,
          icon: 'ship',
          search: false,
          labels: cruiseTypeLabelMap,
        },
      ],
      permanentFilters: [],
    },
  },
  Oceania: {
    facetConfig: {
      facets: [
        {
          apiFields: ['DestID', 'Europe'],
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: FacetType.destination,
          icon: 'map',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_id',
          FacetValue: '14',
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Regent: {
    facetConfig: {
      facets: [
        {
          apiFields: ['DestID', 'Europe'],
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: FacetType.destination,
          icon: 'map',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_id',
          FacetValue: '8116',
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Silversea: {
    facetConfig: {
      facets: [
        {
          apiFields: ['DestID', 'Europe'],
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: FacetType.destination,
          icon: 'map',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_id',
          FacetValue: '8115',
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
  Windstar: {
    facetConfig: {
      facets: [
        {
          apiFields: ['DestID', 'Europe'],
          fields: ['dest_id', 'europe'],
          label: 'Destination',
          type: FacetType.destination,
          icon: 'map',
          search: true,
        },
        {
          apiField: 'DepartMonth',
          field: 'depart_month',
          label: 'Date',
          type: FacetType.date,
          icon: 'calendar',
        },
        {
          apiField: 'Duration',
          fields: ['min_duration', 'max_duration'],
          label: 'Length',
          type: FacetType.duration,
          icon: 'suitcase',
        },
        {
          apiField: 'MinPrice',
          fields: ['min_price', 'max_price'],
          label: 'Price',
          type: FacetType.price,
          icon: 'dollar-square',
        },
        {
          apiField: 'DepartPort',
          field: 'depart_port',
          label: 'Depart. Port',
          type: FacetType.standard,
          icon: 'anchor',
          search: true,
        },
        {
          apiField: 'ShipName',
          field: 'ship_name',
          label: 'Ship',
          type: FacetType.ship,
          icon: 'ship',
          search: true,
        },
      ],
      permanentFilters: [
        {
          FacetName: 'cruiseline_id',
          FacetValue: '9',
        },
        {
          FacetName: 'signature',
          FacetValue: 'true',
        },
      ],
    },
  },
};
